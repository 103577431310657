<template>
    <div class="py-4 container-fluid">
        <div class="mt-4 row">
            <div class="col-12">
                <div class="card">
                    <!-- Card header -->
                    <div class="card-header border-bottom">
                        <div class="row d-flex align-items-center">
                            <div class="col-6">
                                <h5 class="mb-0">Helyiség Módosítása</h5>
                            </div>
                            <div class="col-6 text-end">
                                <material-button class="float-right btn btm-sm"
                                    @click="this.$router.push({ name: 'Areas List' })">
                                    Vissza
                                </material-button>
                            </div>
                        </div>
                    </div>
                    <!--Card body-->
                    <div class="card-body">
                        <form>
                            <div class="row">
                                <div class="col-5">
                                    <material-input id="name" v-model:value="area.name" label="Név"
                                        name="name" variant="static"></material-input>
                                    <validation-error :errors="apiValidationErrors.name"></validation-error>
                                </div>

                                <div class="col-5">
                                    <label class="form-label mb-0 ms-0">Ingatlan</label>
                                    <select id="realestate" v-model="area.realestate_id" name="realestate" class="form-control">
                                        <option v-for="realestate in getRealEstateList" :key="realestate.id" :value="realestate.id">{{ realestate.name }}</option>
                                    </select>
                                </div> 
                                
                                <div class="col-5">
                                    <material-input id="lot_number" v-model:value="area.lot_number" label="Helyrajzi Szám"
                                        name="lot_number" variant="static"></material-input>
                                    <validation-error :errors="apiValidationErrors.lot_number"></validation-error>
                                </div> 

                                <div class="col-5">
                                    <material-input id="position" v-model:value="area.position" label="Elhelyezkedés"
                                        name="position" variant="static"></material-input>
                                    <validation-error :errors="apiValidationErrors.position"></validation-error>
                                </div>

                                <div class="col-5">
                                    <material-input id="size" v-model:value="area.size" label="Terület"
                                        name="size" variant="static"></material-input>
                                    <validation-error :errors="apiValidationErrors.size"></validation-error>
                                </div>

                                <div class="col-5">
                                    <material-input id="height" v-model:value="area.height" label="Belmagasság"
                                        name="height" variant="static"></material-input>
                                    <validation-error :errors="apiValidationErrors.height"></validation-error>
                                </div>

                                <div class="col-5">
                                    <material-input id="function" v-model:value="area.function" label="Funkció"
                                        name="function" variant="static"></material-input>
                                    <validation-error :errors="apiValidationErrors.function"></validation-error>
                                </div>

                                <div class="col-5">
                                    <material-input id="utilization" v-model:value="area.utilization" label="Hasznosítás"
                                        name="utilization" variant="static"></material-input>
                                    <validation-error :errors="apiValidationErrors.utilization"></validation-error>
                                </div>

                                <div class="col-5">
                                    <material-input id="wrapper_type" v-model:value="area.wrapper_type" label="Padló burkolata"
                                        name="wrapper_type" variant="static"></material-input>
                                    <validation-error :errors="apiValidationErrors.wrapper_type"></validation-error>
                                </div>

                                <div class="col-5">
                                    <label class="form-label mb-0 ms-0">Bérlő</label>
                                    <select id="renter" v-model="area.renter_id" name="renter" class="form-control">
                                        <option v-for="renter in getRenterList" :key="renter.id" :value="renter.id">{{ renter.name }}</option>
                                    </select>
                                </div>

                            </div>

                            <div class="row mt-5 mb-5 d-flex align-items-center">
                                <div class="col-2 text-end mt-5">
                                    <material-button class="float-right btn btm-sm" @click.prevent="handleEdit">Mentés</material-button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import MaterialButton from "@/components/MaterialButton.vue";
import MaterialInput from "@/components/MaterialInput.vue";
import ValidationError from "@/components/ValidationError.vue";
import formMixin from "@/mixins/formMixin.js";
import showSwal from "../../../mixins/showSwal";
import _ from "lodash";

export default {
    name: 'editArea',
    components: {
        MaterialButton,
        MaterialInput,
        ValidationError,
        
    },
    mixins: [formMixin],

    data() {
        return {
            area: {}
        }
    },

    setup() {
        return {
            color: '#33e8f5'
        }
    },
    
    computed: {
        getRealEstateList() {
            return this.$store.getters["realestates/getRealEstatesData"];
        },
        getRenterList() {
            return this.$store.getters["renters/getRentersData"];
        },
    },
    async mounted() {
        await this.$store.dispatch("realestates/getRealEstates");
        await this.$store.dispatch("renters/getRenters");
        try {
            await this.$store.dispatch("areas/getArea", this.$route.params.id);
            this.area = _.omit(this.$store.getters['areas/getArea'], 'links');
           
        } catch (error) {
            showSwal.methods.showSwal({
                type: "error",
                message: `Valami hiba történt!`,
                width: 500
            });
        }
    },
    methods: {
        async handleEdit() {
            this.resetApiValidation();
            try {
                
                await this.$store.dispatch("areas/editArea", this.area);
                showSwal.methods.showSwal({
                    type: "success",
                    message: "Helyiség sikeresen módosítva!",
                    width: 500
                });
                this.$router.push({ name: "Areas List" })
            } catch (error) {
                if (error.response.data.errors) {
                    this.setApiValidation(error.response.data.errors);
                }
                showSwal.methods.showSwal({
                    type: "error",
                    message: "Valami hiba történt!",
                    width: 500
                });

            }
        }
    }
}
</script>