<template>
    <div class="py-4 container-fluid">
        <div class="mt-4 row">
            <div class="col-12">
                <div class="card">
                    <!-- Card header -->
                    <div class="card-header border-bottom">
                        <div class="row d-flex align-items-center">
                            <div class="col-6">
                                <h5 class="mb-0">Almérő Hozzáadása</h5>
                            </div>
                            <div class="col-6 text-end">
                                <material-button class="float-right btn btm-sm"
                                    @click="this.$router.push({ name: 'SubMeters List' })">
                                    Vissza
                                </material-button>
                            </div>
                        </div> 
                    </div>
                    <!--Card body-->
                    <div class="card-body">
                        <form>
                            <div class="row">
                                <div class="col-5">
                                    <label class="form-label mb-0 ms-0">Fogyasztási hely</label>
                                    <select id="choices-realestate" v-model="submeter.realestate_id" name="choices-realestate" class="form-control" @change="setPODs($event)">
                                        <option v-for="realestate in getRealEstateList" :key="realestate.id" :value="realestate.id">{{ realestate.name }}</option>
                                    </select>
                                </div> 

                                <div class="col-5">
                                    <material-input id="area_id" v-model:value="currentRealEstate.name" label="Helyiség"
                                        name="area_id" variant="static"></material-input>
                                    <validation-error :errors="apiValidationErrors.area_id"></validation-error>
                                </div>

                                <div class="col-5">
                                    <label class="form-label mb-0 ms-0">POD</label>
                                    <select id="POD" v-model="submeter.meter_pod" name="POD" class="form-control" >
                                        <option v-for="meters in metersList" :key="meters.id" :value="meters.id">{{ meters.pod }}</option>
                                    </select>
                                    <validation-error :errors="apiValidationErrors.pod"></validation-error>
                                </div>

                                <div class="col-5">
                                <material-input id="product_number" v-model:value="submeter.meter_product_number" label="Főmérő gy. szám"
                                    name="product_number" variant="static"></material-input>
                                <validation-error :errors="apiValidationErrors.product_number"></validation-error>
                                </div>

                                <div class="col-5">
                                    <label>Almérő Neve</label>
                                    <MultiSelect />
                                    <validation-error :errors="apiValidationErrors.name"></validation-error>
                                </div>

                                
                            </div>

                            <div class="row mt-5 mb-5 d-flex align-items-center">

                                <div class="col-2 text-end mt-5">
                                    <material-button class="float-right btn btm-sm" @click.prevent="handleAdd">Hozzáadás</material-button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import * as Choices from "choices.js";
import MaterialButton from "@/components/MaterialButton.vue";
import MaterialInput from "@/components/MaterialInput.vue";
import MultiSelect from "@/components/MultiSelect.vue";
import ValidationError from "@/components/ValidationError.vue";
import formMixin from "@/mixins/formMixin.js";
import showSwal from "../../../mixins/showSwal";
import store from "@/store";
import _ from "lodash";

const getPODList = _.debounce(async function (params) {
    console.log(params.query);
  await store.dispatch("meters/getMeters", {
    filter: {
        ...(params.query ? { realestate_id: params.query } : {}),
    },
  });
}, 300);

export default {
    name: 'addSubMeter',
    components: {
        MaterialButton,
        MaterialInput,
        ValidationError,
        MultiSelect,
    },
    mixins: [formMixin],
    setup() {
        return {
            color: '#33e8f5'
        }
    },
    data() {
        return {
            currentRealEstate: {},
            submeter: {},
            options: [],
        }
    },
    computed: {
        getutilityList() {
            return this.$store.getters["utilities/getUtilitiesData"];
        },
        getRealEstateList() {
            return this.$store.getters["realestates/getRealEstatesData"];
        },
        metersList() {
            return this.$store.getters["meters/getMetersData"];
        },
    },
    watch: {
        metersList: {
            handler: "reactiveTable",
            immediate: false,
            deep: true,
        },
    },
    async mounted() {
        await this.$store.dispatch("utilities/getUtilities");
        await this.$store.dispatch("realestates/getRealEstates");

        if (document.getElementById("choices-realestate")) {
            var realestate = document.getElementById("choices-realestate");
            new Choices(realestate);
        }
        await getPODList({
            query: {},
        });
    },
    
    methods: {
        async handleAdd() {
            this.resetApiValidation();
            try {
                //this.tag.color = this.color.hex
                await this.$store.dispatch("submeters/addSubMeter", this.submeter);
                showSwal.methods.showSwal({
                    type: "success",
                    message: "Almérő sikeresen hozzáadva!",
                    width: 500
                });
                this.$router.push({ name: "SubMeters List" })
            } catch (error) {
                if (error.response.data.errors) {
                    this.setApiValidation(error.response.data.errors);
                }
                showSwal.methods.showSwal({
                    type: "error",
                    message: "Valami hiba történt!",
                    width: 500
                });

            }
        },
        async setPODs(event) {
            await getPODList({
                query: event.target.value,
            });
            this.currentRealEstate = this.getRealEstateList.filter(value => {
                return value.id.includes(event.target.value)
            });
            console.log(this.currentRealEstate[0].city);
            document.getElementById('area_id').value = this.currentRealEstate[0].city;
        },
        async reactiveTable() {
            if (this.metersList.length > 0) {
                console.log(this.metersList);
            }
        }  
    }
}
</script>