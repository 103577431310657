/* eslint-disable no-unused-vars */
import utilitiesService from '../services/utilities.service';

const initialState = { utilities: null };

export const utilities = {
    namespaced: true,
    state: initialState,
    actions: { 
        async getUtilities({ commit }, options) {
            const utilities = await utilitiesService.getUtilities(options);
            commit('getUtilitiesSuccess', utilities);
        },
    },
    mutations: {
        getUtilitiesSuccess(state, utilities) {
            state.utilities = utilities;
        },
    },
    getters: {
        getUtilitiesData(state) {
            return state.utilities?.data;
        },
    }
}

