<template>
  <CMultiSelect allowCreateOptions :options="options" />
</template>

<script>
import { CMultiSelect } from '@coreui/vue-pro';


export default {
  name: "MultiSelect",
  components: {
    CMultiSelect,
  },
  data() {
    return {
      options: [{
      
    }]
    }
  },
};
</script>

<style scoped lang="css">
  @import '../../node_modules/@coreui/coreui-pro/dist/css/coreui.min.css';
</style>
