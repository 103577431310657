<template>
    <div class="py-4 container-fluid">
        <div class="mt-4 row">
            <div class="col-12">
                <div class="card">
                    <!-- Card header -->
                    <div class="card-header border-bottom">
                        <div class="row d-flex align-items-center">
                            <div class="col-6">
                                <h5 class="mb-0">Bérlő Módosítása</h5>
                            </div>
                            <div class="col-6 text-end">
                                <material-button class="float-right btn btm-sm"
                                    @click="this.$router.push({ name: 'Renters List' })">
                                    Vissza
                                </material-button>
                            </div>
                        </div>
                    </div>
                    <!--Card body-->
                    <div class="card-body">
                        <form>
                            <div class="row">
                                <div class="col-5">
                                    <material-input id="name" v-model:value="renter.name" label="Cégnév/Név"
                                        name="name" variant="static"></material-input>
                                    <validation-error :errors="apiValidationErrors.name"></validation-error>
                                </div>

                                <div class="col-5">
                                <material-input id="vat_number" v-model:value="renter.vat_number" label="Adószám"
                                    name="vat_number" variant="static"></material-input>
                                <validation-error :errors="apiValidationErrors.vat_number"></validation-error>
                                </div>

                                <div class="col-5">
                                <material-input id="manager" v-model:value="renter.manager" label="Ügyvezető"
                                    name="manager" variant="static"></material-input>
                                <validation-error :errors="apiValidationErrors.manager"></validation-error>
                                </div>

                                <div class="col-5">
                                <material-input id="contact" v-model:value="renter.contact" label="Kapcsolattartó"
                                    name="contact" variant="static"></material-input>
                                <validation-error :errors="apiValidationErrors.contact"></validation-error>
                                </div>

                                <div class="col-5">
                                <material-input id="phone_number" v-model:value="renter.phone_number" label="Telefonszám"
                                    name="phone_number" variant="static"></material-input>
                                <validation-error :errors="apiValidationErrors.phone_number"></validation-error>
                                </div>

                                <div class="col-5">
                                <material-input id="address" v-model:value="renter.address" label="Levelezési cím"
                                    name="address" variant="static"></material-input>
                                <validation-error :errors="apiValidationErrors.address"></validation-error>
                                </div>

                                <div class="col-5">
                                <material-input id="email" v-model:value="renter.email" label="Kapcsolattartási Email"
                                    name="email" variant="static"></material-input>
                                <validation-error :errors="apiValidationErrors.email"></validation-error>
                                </div>

                            </div>

                            <div class="row mt-5 mb-5 d-flex align-items-center">
                                <div class="col-2 text-end mt-5">
                                    <material-button class="float-right btn btm-sm" @click.prevent="handleEdit">Módosít</material-button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import MaterialButton from "@/components/MaterialButton.vue";
import MaterialInput from "@/components/MaterialInput.vue";
import ValidationError from "@/components/ValidationError.vue";
import formMixin from "@/mixins/formMixin.js";
import showSwal from "../../../mixins/showSwal";
import _ from "lodash"

export default {
    name: 'editRenter',
    components: {
        MaterialButton,
        MaterialInput,
        ValidationError,
    },
    mixins: [formMixin],
    data() {
        return {
            renter: {}
        }
    },
    setup() {
        return {
            color: '#33e8f5'
        }
    },
    async mounted() {
        try {
            await this.$store.dispatch("renters/getRenter", this.$route.params.id);
            this.renter = _.omit(this.$store.getters['renters/getRenter'], 'links');
        } catch (error) {
            showSwal.methods.showSwal({
                type: "error",
                message: `Valami hiba történt!`,
                width: 500
            });
        }
    },
    methods: {
        async handleEdit() {
            this.resetApiValidation();
            try {
                
                await this.$store.dispatch("renters/editRenter", this.renter);
                showSwal.methods.showSwal({
                    type: "success",
                    message: "Bérlő sikeresen módosítva!",
                    width: 500
                });
                this.$router.push({ name: "Renters List" })
            } catch (error) {
                if (error.response.data.errors) {
                    this.setApiValidation(error.response.data.errors);
                }
                showSwal.methods.showSwal({
                    type: "error",
                    message: "Valami hiba történt!",
                    width: 500
                });

            }
        }
    }
}
</script>