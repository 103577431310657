<template>
    <div class="py-4 container-fluid">
        <div class="mt-4 row">
            <div class="col-12">
                <div class="card">
                    <!-- Card header -->
                    <div class="card-header border-bottom">
                        <div class="row d-flex align-items-center">
                            <div class="col-6">
                                <h5 class="mb-0">Ingatlan Hozzáadása</h5>
                            </div> 
                            <div class="col-6 text-end">
                                <material-button class="float-right btn btm-sm"
                                    @click="this.$router.push({ name: 'RealEstates List' })">
                                    Vissza
                                </material-button>
                            </div>
                        </div>
                    </div>
                    <!--Card body-->
                    <div class="card-body">
                        <form>
                            <div class="row">
                                <div class="col-5">

                                    <material-input id="name" v-model:value="realestate.name" label="Név"
                                        name="name" variant="static"></material-input>
                                    <validation-error :errors="apiValidationErrors.name"></validation-error>

                                </div>
                                <div class="col-5">

                                    <label class="form-label mb-0 ms-0">Country</label>
                                    <select id="country_code" v-model="realestate.country_code" name="country_code" class="form-control">
                                        <option v-for="country in getcountryList" :key="country.code" :value="country.code">{{ country.name }}</option>
                                    </select>

                                </div>
                                <div class="col-5">

                                    <material-input id="city" v-model:value="realestate.city" label="Város"
                                        name="city" variant="static"></material-input>
                                    <validation-error :errors="apiValidationErrors.city"></validation-error>

                                </div>
                                <div class="col-5">

                                <material-input id="zip" v-model:value="realestate.zip" label="Irányítószám"
                                    name="zip" variant="static"></material-input>
                                <validation-error :errors="apiValidationErrors.zip"></validation-error>

                                </div>

                                <div class="col-5">

                                    <material-input id="address" v-model:value="realestate.address" label="Cím"
                                        name="address" variant="static"></material-input>
                                    <validation-error :errors="apiValidationErrors.address"></validation-error>

                                </div>
                                <div class="col-5">

                                <material-input id="lot_number" v-model:value="realestate.lot_number" label="Helyrajzi Szám"
                                    name="lot_number" variant="static"></material-input>
                                <validation-error :errors="apiValidationErrors.lot_number"></validation-error>

                                </div>

                                <div class="col-5">
                                    <label class="form-label mb-0 ms-0">Tulajdonos</label>
                                    <select id="owner" v-model="realestate.owner_id" name="owner" class="form-control">
                                        <option v-for="owner in getownerList" :key="owner.id" :value="owner.id">{{ owner.name }}</option>
                                    </select>
                                </div> 
                                
                            </div>

                            <div class="row mt-5 mb-5 d-flex align-items-center">

                                <div class="col-2 text-end mt-5">
                                    <material-button class="float-right btn btm-sm" @click.prevent="handleAdd">Hozzáadás</material-button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import MaterialButton from "@/components/MaterialButton.vue";
import MaterialInput from "@/components/MaterialInput.vue";
import ValidationError from "@/components/ValidationError.vue";
import formMixin from "@/mixins/formMixin.js";
import showSwal from "../../../mixins/showSwal";

export default {
    name: 'AddRealEstate',
    components: {
        MaterialButton,
        MaterialInput,
        ValidationError
    },
    mixins: [formMixin],
    setup() {
        return {
            color: '#33e8f5'
        }
    },
    data() {
        return {
            realestate: {}
        }
    },
    computed: {
        getcountryList() {
            return this.$store.getters["countries/getCountriesData"];
        },
        getownerList() {
            return this.$store.getters["owners/getOwnersData"];
        }
    },
    async mounted() {
        await this.$store.dispatch("countries/getCountries");
        await this.$store.dispatch("owners/getOwners");
    },
    methods: {
        async handleAdd() {
            this.resetApiValidation();
            try {
                //this.tag.color = this.color.hex
                await this.$store.dispatch("realestates/addRealEstate", this.realestate);
                showSwal.methods.showSwal({
                    type: "success",
                    message: "Ingatlan sikeresen hozzáadva!",
                    width: 500
                });
                this.$router.push({ name: "RealEstates List" })
            } catch (error) {
                if (error.response.data.errors) {
                    this.setApiValidation(error.response.data.errors);
                }
                showSwal.methods.showSwal({
                    type: "error",
                    message: "Valami hiba történt!",
                    width: 500
                });

            }
        }
    }
}
</script>