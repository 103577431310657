<template>
    <div class="py-4 container-fluid">
        <div class="mt-4 row">
            <div class="col-12">
                <div class="card">
                    <!-- Card header -->
                    <div class="card-header border-bottom">
                        <div class="row d-flex align-items-center">
                            <div class="col-6">
                                <h5 class="mb-0">Elszámoló Lap</h5>
                            </div>
                            <div class="col-6 text-end">
                                <material-button class="float-right btn btm-sm"
                                    @click="this.$router.push({ name: 'SubMeters List' })">
                                    Vissza
                                </material-button>
                            </div>
                        </div> 
                    </div>
                    <!--Card body-->
                    
                </div>
            </div>
        </div>
    </div>
</template>