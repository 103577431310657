/* eslint-disable no-unused-vars */
import rentersService from '../services/renters.service';

const initialState = { renters: null, renter: null };

export const renters = {
    namespaced: true,
    state: initialState,
    actions: {
        async getRenters({ commit }, options) {
            const renters = await rentersService.getRenters(options);
            commit('getRentersSuccess', renters);
        },

        async addRenter({ commit }, newRenter) {
            await rentersService.addRenter(newRenter);
        },

        async deleteRenter({ commit }, renterId) {
            await rentersService.deleteRenter(renterId);
        },

        async getRenter({ commit }, renterId) {
            const renter = await rentersService.getRenter(renterId);
            commit('getRenterSuccess', renter);
        },

        async editRenter({ commit }, modifiedRenter) {
            await rentersService.editRenter(modifiedRenter); 
        },


    },
    mutations: {
        getRentersSuccess(state, renters) {
            state.renters = renters;
        },
        getRenterSuccess(state, renter) {
            state.renter = renter;
        },
    },
    getters: {
        getRentersData(state) {
            return state.renters?.data;
        },
        getRentersMeta(state) {
            return state.renters?.meta;
        },
        getRenter(state){
            return state.renter;
        }
    }
}

