<template>
    <div class="py-4 container-fluid">
        <div class="mt-4 row">
            <div class="col-12">
                <div class="card">
                    <!-- Card header -->
                    <div class="card-header border-bottom">
                        <div class="row d-flex align-items-center">
                            <div class="col-6">
                                <h5 class="mb-0">Tulajdonos Hozzáadása</h5>
                            </div> 
                            <div class="col-6 text-end">
                                <material-button class="float-right btn btm-sm"
                                    @click="this.$router.push({ name: 'Owners List' })">
                                    Vissza
                                </material-button>
                            </div>
                        </div>
                    </div>
                    <!--Card body-->
                    <div class="card-body">
                        <form>
                            <div class="row">
                                <div class="col-5">
                                    <material-input id="name" v-model:value="owner.name" label="Cégnév/Név"
                                        name="name" variant="static"></material-input>
                                    <validation-error :errors="apiValidationErrors.name"></validation-error>
                                </div>
                                
                                <div class="col-5">
                                    <material-input id="vat_number" v-model:value="owner.vat_number" label="Adószám"
                                        name="vat_number" variant="static"></material-input>
                                    <validation-error :errors="apiValidationErrors.vat_number"></validation-error>
                                </div>

                                <div class="col-5">
                                    <material-input id="manager" v-model:value="owner.manager" label="Ügyvezető"
                                        name="manager" variant="static"></material-input>
                                    <validation-error :errors="apiValidationErrors.manager"></validation-error>
                                </div>

                                <div class="col-5">
                                    <material-input id="contact" v-model:value="owner.contact" label="Kapcsolattartó"
                                        name="contact" variant="static"></material-input>
                                    <validation-error :errors="apiValidationErrors.contact"></validation-error>
                                </div>

                                <div class="col-5">
                                    <material-input id="phone_number" v-model:value="owner.phone_number" label="Telefonszám"
                                        name="phone_number" variant="static"></material-input>
                                    <validation-error :errors="apiValidationErrors.phone_number"></validation-error>
                                </div>

                                <div class="col-5">
                                    <material-input id="address" v-model:value="owner.address" label="Levelezési cím"
                                        name="address" variant="static"></material-input>
                                    <validation-error :errors="apiValidationErrors.address"></validation-error>
                                </div>

                                <div class="col-5">
                                    <material-input id="email" v-model:value="owner.email" label="Kapcsolattartási Email"
                                        name="email" variant="static"></material-input>
                                    <validation-error :errors="apiValidationErrors.email"></validation-error>
                                </div>
                                
                            </div>

                            <div class="row mt-5 mb-5 d-flex align-items-center">

                                <div class="col-2 text-end mt-5">
                                    <material-button class="float-right btn btm-sm" @click.prevent="handleAdd">Hozzáadás</material-button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import MaterialButton from "@/components/MaterialButton.vue";
import MaterialInput from "@/components/MaterialInput.vue";
import ValidationError from "@/components/ValidationError.vue";
import formMixin from "@/mixins/formMixin.js";
import showSwal from "../../../mixins/showSwal";

export default {
    name: 'addOwner',
    components: {
        MaterialButton,
        MaterialInput,
        ValidationError
    },
    mixins: [formMixin],
    setup() {
        return {
            color: '#33e8f5'
        }
    },
    data() {
        return {
            owner: {}
        }
    },
    
    
    methods: {
        async handleAdd() {
            this.resetApiValidation();
            try {
                //this.tag.color = this.color.hex
                await this.$store.dispatch("owners/addOwner", this.owner);
                showSwal.methods.showSwal({
                    type: "success",
                    message: "Tulajdonos sikeresen hozzáadva!",
                    width: 500
                });
                this.$router.push({ name: "Owners List" })
            } catch (error) {
                if (error.response.data.errors) {
                    this.setApiValidation(error.response.data.errors);
                }
                showSwal.methods.showSwal({
                    type: "error",
                    message: "Valami hiba történt!",
                    width: 500
                });

            }
        }
    }
}
</script>