<template>
    <div class="py-4 container-fluid">
        <div class="mt-4 row">
            <div class="col-12">
                <div class="card">
                    <!-- Card header -->
                    <div class="card-header border-bottom">
                        <div class="row d-flex align-items-center">
                            <div class="col-6">
                                <h5 class="mb-0">Főmérő Hozzáadása</h5>
                            </div>
                            <div class="col-6 text-end">
                                <material-button class="float-right btn btm-sm"
                                    @click="this.$router.push({ name: 'Meters List' })">
                                    Vissza
                                </material-button>
                            </div>
                        </div> 
                    </div>
                    <!--Card body-->
                    <div class="card-body">
                        <form>
                            <div class="row">
                                <div class="col-5">
                                    <material-input id="name" v-model:value="meter.name" label="Név"
                                        name="name" variant="static"></material-input>
                                    <validation-error :errors="apiValidationErrors.name"></validation-error>
                                </div>

                                <div class="col-5">
                                    <label class="form-label mb-0 ms-0">Fogyasztási hely</label>
                                    <select id="choices-realestate" v-model="meter.realestate_id" name="choices-realestate" class="form-control" >
                                        <option v-for="realestate in getRealEstateList" :key="realestate.id" :value="realestate.id">{{ realestate.name }}</option>
                                    </select>
                                </div> 

                                <div class="col-5">
                                    <label class="form-label mb-0 ms-0">Közmű</label>
                                    <select id="choices-mutility" v-model="meter.mutility" name="choices-mutility" class="form-control">
                                        <option v-for="utility in getutilityList" :key="utility.name" :value="utility.name">{{ utility.name }}</option>
                                    </select>
                                </div> 

                                <div class="col-5">
                                    <material-input id="POD" v-model:value="meter.pod" label="POD"
                                        name="POD" variant="static"></material-input>
                                    <validation-error :errors="apiValidationErrors.pod"></validation-error>
                                </div>

                                <div class="col-5">
                                <material-input id="product_number" v-model:value="meter.product_number" label="Főmérő gy. szám"
                                    name="product_number" variant="static"></material-input>
                                <validation-error :errors="apiValidationErrors.product_number"></validation-error>
                                </div>

                                
                            </div>

                            <div class="row mt-5 mb-5 d-flex align-items-center">

                                <div class="col-2 text-end mt-5">
                                    <material-button class="float-right btn btm-sm" @click.prevent="handleAdd">Hozzáadás</material-button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import * as Choices from "choices.js";
import MaterialButton from "@/components/MaterialButton.vue";
import MaterialInput from "@/components/MaterialInput.vue";
import ValidationError from "@/components/ValidationError.vue";
import formMixin from "@/mixins/formMixin.js";
import showSwal from "../../../mixins/showSwal";

export default {
    name: 'addMeter',
    components: {
        MaterialButton,
        MaterialInput,
        ValidationError
    },
    mixins: [formMixin],
    setup() {
        return {
            color: '#33e8f5'
        }
    },
    data() {
        return {
            meter: {}
        }
    },
    computed: {
        getutilityList() {
            return this.$store.getters["utilities/getUtilitiesData"];
        },
        getRealEstateList() {
            return this.$store.getters["realestates/getRealEstatesData"];
        }
    },
    async mounted() {
        await this.$store.dispatch("utilities/getUtilities");
        await this.$store.dispatch("realestates/getRealEstates");
        
        if (document.getElementById("choices-realestate")) {
            var realestate = document.getElementById("choices-realestate");
            new Choices(realestate);
        }

        if (document.getElementById("choices-mutility")) {
            var mutility = document.getElementById("choices-mutility");
            new Choices(mutility);
        }
    },
    
    methods: {
        async handleAdd() {
            this.resetApiValidation();
            try {
                //this.tag.color = this.color.hex
                await this.$store.dispatch("meters/addMeter", this.meter);
                showSwal.methods.showSwal({
                    type: "success",
                    message: "Főmérő sikeresen hozzáadva!",
                    width: 500
                });
                this.$router.push({ name: "Meters List" })
            } catch (error) {
                if (error.response.data.errors) {
                    this.setApiValidation(error.response.data.errors);
                }
                showSwal.methods.showSwal({
                    type: "error",
                    message: "Valami hiba történt!",
                    width: 500
                });

            }
        }
    }
}
</script>