<template>
    <div class="py-4 container-fluid">
        <div class="mt-4 row">
            <div class="col-12">
                <div class="card">
                    <!-- Card header -->
                    <div class="card-header border-bottom">
                        <div class="row d-flex align-items-center">
                            <div class="col-6">
                                <h5 class="mb-0">Beérkező Számla Módosítása</h5>
                            </div>
                            <div class="col-6 text-end">
                                <material-button class="float-right btn btm-sm"
                                    @click="this.$router.push({ name: 'InInvoice List' })">
                                    Vissza
                                </material-button>
                            </div>
                        </div>
                    </div>
                    <!--Card body-->
                    <div class="card-body">
                        <form>
                            <div class="row">
                                <div class="col-5">
                                    <VueDatePicker v-model="month" :teleport="true" locale="hu" month-picker />
                                </div>
                            
                                <div class="col-5">
                                    <label class="form-label mb-0 ms-0">Fogyasztási hely</label>
                                    <select id="realestate" v-model="invoice.realestate_id" name="realestate" class="form-control">
                                        <option v-for="realestate in getRealEstateList" :key="realestate.id" :value="realestate.id">{{ realestate.name }}</option>
                                    </select>
                                </div>

                                <div class="col-10">
                                    <material-input id="invoice_number" v-model:value="invoice.invoice_number" label="Számla száma"
                                        name="invoice_number" variant="static"></material-input>
                                    <validation-error :errors="apiValidationErrors.invoice_number"></validation-error>
                                </div>

                                <div class="col-5">
                                    <label class="form-label mb-0 ms-0">Főmérő</label>
                                    <select id="meter_id" v-model="invoice.meter_id" name="meter_id" class="form-control">
                                        <option v-for="meter in getMeterList" :key="meter.id" :value="meter.id">{{ meter.name }}</option>
                                    </select>
                                </div>

                                <div class="col-5">
                                    <label class="form-label mb-0 ms-0">Almérő</label>
                                    <select id="submeter_id" v-model="invoice.submeter_id" name="submeter_id" class="form-control">
                                        <option v-for="submeter in getSubMeterList" :key="submeter.id" :value="submeter.id">{{ submeter.name }}</option>
                                    </select>
                                </div>

                                <div class="col-5">
                                    <material-input id="consumption" v-model:value="invoice.consumption" label="Fogyasztás"
                                        name="consumption" variant="static"></material-input>
                                    <validation-error :errors="apiValidationErrors.consumption"></validation-error>
                                </div>

                                <div class="col-5">
                                    <material-input id="gross_amount" v-model:value="invoice.gross_amount" label="Nettó összeg"
                                        name="gross_amount" variant="static"></material-input>
                                    <validation-error :errors="apiValidationErrors.gross_amount"></validation-error>
                                </div>

                                
                            </div>

                            <div class="row mt-5 mb-5 d-flex align-items-center">

                                <div class="col-2 text-end mt-5">
                                    <material-button class="float-right btn btm-sm" @click.prevent="handleEdit">Módosítás</material-button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { ref } from 'vue';
import MaterialButton from "@/components/MaterialButton.vue";
import MaterialInput from "@/components/MaterialInput.vue";
import ValidationError from "@/components/ValidationError.vue";
import formMixin from "@/mixins/formMixin.js";
import showSwal from "../../../mixins/showSwal";
import VueDatePicker from '@vuepic/vue-datepicker';
import _ from "lodash"

export default {
    name: 'editInInvoice',
    components: {
        MaterialButton, 
        MaterialInput,
        VueDatePicker,
        ValidationError,
    },
    mixins: [formMixin],
    setup() {
        const month = ref({
            month: new Date().getMonth(),
            year: new Date().getFullYear()
        });
        return {
            color: '#33e8f5',
            month
        }
    },
    data() {
        return {
            invoice: {},
            date: null,
        }
    },
    computed: {
        getutilityList() {
            return this.$store.getters["utilities/getUtilitiesData"];
        },
        getRealEstateList() {
            return this.$store.getters["realestates/getRealEstatesData"];
        },
        getMeterList() {
            return this.$store.getters["meters/getMetersData"];
        },
        getSubMeterList() {
            return this.$store.getters["submeters/getSubMetersData"];
        }
    },
    async mounted() {
        await this.$store.dispatch("utilities/getUtilities");
        await this.$store.dispatch("realestates/getRealEstates");
        await this.$store.dispatch("meters/getMeters");
        await this.$store.dispatch("submeters/getSubMeters");
        try {
            await this.$store.dispatch("incominginvoices/getIncomingInvoice", this.$route.params.id);
            this.invoice = _.omit(this.$store.getters['incominginvoices/getIncomingInvoice'], 'links');
        } catch (error) {
            showSwal.methods.showSwal({
                type: "error",
                message: `Valami hiba történt!`,
                width: 500
            });
        }
    },
    methods: {
        async handleEdit() {
            this.resetApiValidation();
            try {
                
                await this.$store.dispatch("incominginvoices/editIncomingInvoice", this.invoice);
                showSwal.methods.showSwal({
                    type: "success",
                    message: "Beérkező Számla sikeresen módosítva!",
                    width: 500
                });
                this.$router.push({ name: "InInvoice List" })
            } catch (error) {
                if (error.response.data.errors) {
                    this.setApiValidation(error.response.data.errors);
                }
                showSwal.methods.showSwal({
                    type: "error",
                    message: "Valami hiba történt!",
                    width: 500
                });

            }
        }
    }
}
</script>